import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

const Tags = ({ pageContext, data, location }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } for "${tag}"`
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={tagHeader} description={`${tagHeader}`} />
      <div>
        <h1
          className="text-white"
          style={{
            marginTop: rhythm(1),
            marginBottom: 30,
          }}
        >
          {tagHeader}
        </h1>
        <div className="mt-48">
          <ul>
            {edges.map(({ node }) => {
              const { slug } = node.fields
              const { title, description, date } = node.frontmatter

              return (
                <div className="mb-10" key={slug}>
                  <h3 className="mb-2">
                    <Link style={{ boxShadow: `none` }} to={slug}>
                      {title}
                    </Link>
                  </h3>
                  <small className="block mb-2 text-sm font-bodythin">
                    {date}
                  </small>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: description || node.excerpt,
                    }}
                  />
                </div>
              )
            })}
          </ul>
        </div>
      </div>
    </Layout>
  )
}
Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}
export default Tags
export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
